import styled from "styled-components"

export const Title = styled.h2`
  text-align: center;
  font-size: 17px;
  line-height: 26px;
  margin: -20px auto 0;
  display: block;
  width: 700px;
  max-width: 100%;
`
export const Wrap = styled.div`
  margin: 40px auto 0;
  width: 600px;
  max-width: 100%;
  form {
    padding: 40px;
    border: 1px solid #fff;
    display: flex;
    flex-direction: column;
  }
  input {
    border: none;
    font-size: 16px;
    padding: 12px 15px;
    margin-bottom: 40px;
  }
  select {
    border: none;
    font-size: 16px;
    padding: 12px 15px;
    margin-bottom: 40px;
    width: 100%;
  }
`
export const Label = styled.label`
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
`
export const Button = styled.button`
  border: 1px solid #fff;
  padding:0 20px;
  height: 50px;
  text-transform: uppercase;
  font-size: 14px;
  background: transparent;
  color: #fff;
  margin-top: 40px;
  cursor: pointer;
`
