import * as React from "react"
import { FunctionComponent, useState } from "react"
import Header from "../../components/header/Header"
import { graphql } from "gatsby"
import { Wrap, Title, Label, Button } from "./Jobs.css"
import MainWrap from "../../components/main-wrap/MainWrap"
import MainTitle from "../../components/main-title/MainTitle"
import { Lang, PageContext } from "../../constants"
import { HolderWrap } from "../../components/main-wrap/MainWrap.css"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import NativeSelect from "@material-ui/core/NativeSelect"

export type Props = {
  data?: any
  pageContext: PageContext
}
export type Functions = {}
export type AllProps = Props & Functions

export const Jobs: FunctionComponent<AllProps> = ({ data, pageContext }) => {
  const getValue = (param, object = null) => {
    try {
      const query = object || data.jobs.nodes[0].frontmatter
      return pageContext.lang === Lang.LV ? query[param] : query[`${param}_en`]
    } catch (e) {
      console.log({ param, object, data })
      console.warn(e)
      return ""
    }
  }
  const restaurants = data.restaurants.nodes
  console.log({ data, restaurants })
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [restaurant, setRestaurant] = useState<string>("")

  return (
    <MainWrap>
      <Header lang={pageContext.lang} title={"Delisnack darbs"} />
      <MainTitle>
        {pageContext.lang === Lang.LV ? "Pievienojies!!" : "Join us"}
      </MainTitle>
      <HolderWrap>
        <Title>{getValue("title")}</Title>
        <Wrap>
          <form
            method="POST"
            encType="multipart/form-data"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="darbs"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="darbs" />
            <input
              name="name"
              type="text"
              placeholder={
                pageContext.lang === Lang.LV
                  ? "Vārds, Uzvārds"
                  : "Name, Surname"
              }
            />
            <input
              name="email"
              type="text"
              placeholder={
                pageContext.lang === Lang.LV ? "Jūsu e-pasts" : "Your e-mail"
              }
            />
            <div>
              <Label htmlFor="restaurant">
                {pageContext.lang === Lang.LV
                  ? "Vēlamais restorāns"
                  : "Preferred restaurant"}
              </Label>
              {restaurants && restaurants.length && (
                <select id="restaurant" name="restaurant">
                  {restaurants.map(({ frontmatter }) => {
                    return (
                      <option value={frontmatter.title} key={frontmatter.title}>
                        {frontmatter.title}
                      </option>
                    )
                  })}
                </select>
              )}
            </div>
            <div>
              <Label htmlFor="cv">
                {pageContext.lang === Lang.LV ? "Jūsu CV" : "Your CV"}
              </Label>
              <input
                style={{ padding: 0, marginBottom: 0 }}
                name="cv"
                type="file"
                id="cv"
                placeholder={
                  pageContext.lang === Lang.LV ? "Jūsu CV" : "Your CV"
                }
              />
            </div>
            <Button type="submit">
              {pageContext.lang === Lang.LV ? "Nosūtīt" : "Send"}
            </Button>
          </form>
        </Wrap>
      </HolderWrap>
    </MainWrap>
  )
}
export default Jobs
export const query = graphql`
  {
    jobs: allMarkdownRemark(
      filter: { fields: { collection: { eq: "jobs" } } }
    ) {
      nodes {
        frontmatter {
          title
          title_en
        }
      }
    }
    restaurants: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "restaurants" } }
        frontmatter: { googleMapEmbed: {}, phone: {}, title: {} }
      }
    ) {
      nodes {
        frontmatter {
          title
          title_en
        }
      }
    }
  }
`
